<template>
  <div>
    <v-list-item-content style="align-self: center" class="py-1 px-1">
      <v-list-item-title
        class="text-capitalize d-flex align-center"
        style="font-size: 14px"
      >
        <HbIcon v-if="contact.rent_as_business" color="#101318" small mdi-code="mdi-domain" />
        <HbIcon v-else color="#101318" small mdi-code="mdi-account" />
        <span class="hb-font-body-medium hb-text-night mx-1"
          >{{ contact.first }}
          {{ contact.last }}</span
        >&nbsp;
        <hb-status v-if="contact.status">{{
          contact.status
        }}</hb-status
        >&nbsp;
        <span class="hb-text-light" v-if="this.displayContactBalance">
          (Balance
          {{
            balance | formatMoney
          }})</span
        >
      </v-list-item-title>
      <v-list-item-subtitle>
        <span
          v-if="
            contact.Phones &&
            contact.Phones.length > 0
          "
          >{{ contact.Phones[0].phone | formatPhone }}</span
        >
        <span
          v-if="
            contact.Phones &&
            contact.Phones.length > 0 &&
            contact.email
          "
        >
          |
        </span>
        <span> {{ contact.email }}</span>
      </v-list-item-subtitle>
      <span
        v-for="(lease, index) in contact.Leases"
        :key="'lease' + index"
      >
        <div v-if="lease.id">
          <v-list-item-subtitle
            class="contact-space-pointer d-flex align-center"
          >
            <HbUnitIcon
              small
              :type="getUnitTypeLabel(lease.Unit)"
            />

            <span class="ml-1">
              {{ lease.Unit.number }} | <span v-if="lease.Unit.Property.number">{{lease.Unit.Property.number}} - </span>{{ lease.Unit.Address | formatAddress('-', true) }}
            </span
          >
          </v-list-item-subtitle>
        </div>
      </span>
    </v-list-item-content>
  </div>        
</template>

<script type="text/babel">
import { mapGetters } from "vuex";

export default {
  name: "LinkContact",
  props: ["contact", "balance"],
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered'
    }),
    displayContactBalance() {
      return this.balance > 0;
    },
  },
  created(){
    this.contact.Leases.map( lease => lease.Unit.Property = this.properties.find(p => p.id === lease.Unit.property_id));
  },
  methods: {
  }
};
</script>
